<template>
  <section class="fifth-section main-section">
    <div class="layout">
      <div class="fifth-section-content">
        <h2 class="fifth-section__title" data-aos="fade-up">
          <b>저도</b><br>
          <b>의료비지원</b><br>
          받을 수 있나요?
        </h2>
        <div class="fifth-section-img-area"  data-aos="fade-up" data-aos-delay="300">
          <img src="@/assets/images/main/fifth-img.png" alt="" class="fifth-section-img">
        </div>
        <dl class="fifth-section-dl first">
          <dt class="fifth-section-dt text-effect-text-wrap">
            <span class="text-effect__text" data-aos="text-effect" data-aos-delay=""><b class="color--point2">국내</b> 거주자</span></dt>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text"  data-aos="text-effect" data-aos-delay="200">- 건강보험 가입자 중 중위소득 100% 이하</span></dd>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text"  data-aos="text-effect" data-aos-delay="400">- 기초생활수급자</span></dd>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text"  data-aos="text-effect" data-aos-delay="600">- 차상위계층</span></dd>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text"  data-aos="text-effect" data-aos-delay="800">- 그 외 의료급여 1종, 2종</span></dd>
        </dl>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FifthSection',
  setup() {
  }
})
</script>

<style scoped lang="scss">
.fifth-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $vary-light-gray2;
  padding-bottom: 22.2vw;

  &__title {
    padding-top: 12vh;
    padding-left: 8.88vw;
    @include setText(28,400,36);
    margin-bottom: 7.4vh;
  }
  &-img {
    width: 64.66%;

  }
  &-dl {
    margin-top: 3.7vh;
    padding-left: 6.66vw;
  }
  &-dt {
    @include setText(18,700,23);
    margin-bottom: 5px;
    letter-spacing: -0.05em;
  }
  &-dd {
    font-size: 13px;
    line-height: 180%;
  }
  .fifth-section-img-area {
    margin-bottom: 11vw;
    text-align: center;
  }
}

</style>
