<template>
  <section class="seventh-section main-section content-section">
    <div class="layout">
      <h2 class="seventh-section__title text-effect-text-wrap">
        <span class="text-effect__text" data-aos="text-effect">
        보이는 희망
        </span>
        <span class="text-effect__text" data-aos="text-effect"  data-aos-delay="500">
        아름다운 미래
        </span>
      </h2>
    </div>
    <div class="seventh-section-slide-area">
      <swiper
          :width="648"
          centeredSlides
          :space-between="0"
      >
            <swiper-slide>
            <div class="slide-item slide">
              <iframe width="678" height="426" src="https://www.youtube.com/embed/IItt4h7sdHI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
          </swiper-slide>
           <swiper-slide>
            <div class="slide-item slide">
              <a href="https://bori-app.notion.site/1587b7eec68a40fdbffa006a79688b31" target="_blank">
                <img src="@/assets/images/main/video-img2.png" alt="" class="" height="430">
              </a>
            </div>
          </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'

export default defineComponent({
  name: 'SeventhSection',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {

    return {
    }
  }
})
</script>

<style scoped lang="scss">
.seventh-section {
  flex-direction: column;
  overflow: hidden;
  .layout {
    display: block;
    padding: 0 5%;
  }
  &__title {
    @include setText(46,400,60);
    margin-bottom: 7.4vh;
  }
  &__description {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 11.11vw;
    padding-left: 8.88vw;
  }
  &-slide-area {
    width: 100%;
    height: 426px;
    position: relative;
    ::v-deep {
      .swiper-slide {
        margin-left: 100px;
      }
    }

    .slide-item {

    }
  }
}
</style>
