<template>
  <section class="second-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text second-section-title is--active"  data-aos="fade-up" >
         보리<br> <b>누적지원건</b>
      </p>
    </div>
    <div class="middle-section-count">
      <div  data-aos="fade-up" data-aos-delay="300" ref="countElement" class="count-element">
        <span class="count__number target" id="numberCount"></span>
        <span class="count__unit">건</span>
      </div>
      <p class="count__period" data-aos="fade-up" data-aos-delay="600">2019년 1월부터 2022년 말까지 기준</p>
    </div>
  </section>
</template>

<script>
import { CountUp } from 'countup.js/dist/countUp.js'
import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'SecondSection',
  components: {
  },
  setup () {
    const easingFn = function (t, b, c, d) {
      const ts = (t /= d) * t;
      const tc = ts * t;
      return b + c * (tc + -3 * ts + 3 * t);
    }
    const options = {
      startVal: 1000,
      duration: 2,
      easingFn
    }
    const countElement = ref(null)
    const onClassChange = (classAttrValue) => {
      const classList = classAttrValue.split(' ');
      if (classList.includes('aos-animate')) {
        const countUp = new CountUp('numberCount', 3463, options);
        countUp.start();
        observer.disconnect();
      }
    }

    const observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        nextTick(() => {
          onClassChange(newValue, m.oldValue);
        });
      }
    });

    onMounted(() => {
      observer.observe(countElement.value, {
        attributes: true,
        attributeOldValue : true,
        attributeFilter: ['class'],
      });
    })

    onBeforeUnmount(() => {
      observer.disconnect();
    })
    return {
      countElement
    }
  }
})
</script>

<style scoped lang="scss">
.second-section {
  background-color: $point;
  color: $white;
  background-image: url("../../../assets/images/main/second-bg.png");
  background-size: 334px;
  background-position: 50% 90%;
  background-repeat: no-repeat;
  @include respond-to() {
    background-size: 257px;
  }
}
@include tabletMiddle();
</style>
