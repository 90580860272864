<template>
  <section class="fifth-section main-section">
    <div class="layout">
      <div class="fifth-section-content">
        <h2 class="fifth-section__title">
          <b>저도</b><br>
          <b>의료비지원</b><br>
          받을 수 있나요?
        </h2>
        <dl class="fifth-section-dl first">
          <dt class="fifth-section-dt text-effect-text-wrap">
            <span class="text-effect__text"><b class="color--point2">국내</b> 거주자</span></dt>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text">- 건강보험 가입자 중 중위소득 100% 이하</span></dd>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text">- 기초생활수급자</span></dd>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text">- 차상위계층</span></dd>
          <dd class="fifth-section-dd text-effect-text-wrap">
            <span class="text-effect__text">- 그 외 의료급여 1종, 2종</span></dd>
        </dl>
      </div>
      <div class="fifth-section-img-area">
        <img src="@/assets/images/main/fifth-img.png" alt="" class="fifth-section-img">
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FifthSection',
  setup() {

    return {
    }
  }
})
</script>

<style scoped lang="scss">
.fifth-section {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $vary-light-gray2;

  .layout {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  &__title {
    @include fadeInUpPrepare();
    @include setText(56,400,70);
    margin-bottom: 7.4vh;
  }
  &-img {
    width: 560px;
  }
  &-dl {
    margin-top: 3.7vh;
      &.first {
        .fifth-section-dt {
          .text-effect__text {
            @include fadeTextPrepare(.2);
          }
        }

        .fifth-section-dd {
          //@include fadeInUpPrepare(.5);
          .text-effect__text {
            @include fadeTextPrepare(.5);
          }

          & + .fifth-section-dd {
            //@include fadeInUpPrepare(.7);
            .text-effect__text {
              @include fadeTextPrepare(.7);
            }

            & + .fifth-section-dd {
              .text-effect__text {
                @include fadeTextPrepare(.9);
              }

              //@include fadeInUpPrepare(.9);
              & + .fifth-section-dd {
                overflow: hidden;

                .text-effect__text {
                  @include fadeTextPrepare(1.1);
                }

                //@include fadeInUpPrepare(1.1);
              }
            }
          }
        }
      }
      &.second {
        .fifth-section-dt {
          .text-effect__text {
            @include fadeTextPrepare(1.3);
          }

          //@include fadeInUpPrepare(1.3);
        }

        .fifth-section-dd {
          //@include fadeInUpPrepare(1.5);
          .text-effect__text {
            @include fadeTextPrepare(1.5);
          }

          & + .fifth-section-dd {
            //  @include fadeInUpPrepare(1.7);
            .text-effect__text {
              @include fadeTextPrepare(1.7);
            }

            & + .fifth-section-dd {
              //    @include fadeInUpPrepare(1.9);
              .text-effect__text {
                @include fadeTextPrepare(1.9);
              }

              & + .fifth-section-dd {
                @include fadeTextPrepare(2.1);
                //@include fadeInUpPrepare(2.1);
              }
            }
          }
        }
      }
  }
  &-dt {
    margin-bottom: 16px;
    @include setText(32,400,38);
    letter-spacing: -0.05em;
  }
  &-dd {
    font-size: 20px;
    line-height: 180%;
  }
  .fifth-section-img-area {
    .fifth-section-img {
      @include fadeLeftPrepare(2);
    }
  }
}
.section.active {
  .fifth-section .layout {
    .fifth-section-dl {
      .fifth-section-dt,
      .fifth-section-dd {
        .text-effect__text {
          @include fadeText();
        }
      }
    }
  }

  .fifth-section__title {
    @include fadeInUp();
  }

  .fifth-section-img-area {
    .fifth-section-img {
      @include fadeLeft()
    }
  }
}
</style>
