<template>
  <section class="first-section main-section">
    <div class="first-section-content">
      <div class="section-text">
        <h1 class="section__title"  data-aos="fade-up" data-aos-delay="300">
          보이는<br> 희망의 시작<br>
          <strong class="section__title--impact">보리</strong>
        </h1>
        <p class="section__description" data-aos="fade-up" data-aos-delay="600">
          <span class="section__description-text"><b>대한민국 30%</b>를 위한 의료비지원 플랫폼</span>
        </p>
      </div>
      <div class="first-section-img-area" data-aos="fade-up" data-aos-delay="900">
        <img src="@/assets/images/main/first-img-mobile.png" alt="보리의 화면" class="first-section-img">
      </div>
    </div>
    <div class="section-bottom-img left"></div>
    <div class="section-bottom-img right"></div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FirstSection',
  setup() {
    return {
    }
  }
})
</script>

<style lang="scss" scoped>
.first-section {
  display: flex;
  overflow: hidden;
  padding-left: 0;
  padding-top: 12vh;
  align-items: flex-start;
}
.section-text {
  padding-left: 11vw;

  .section {
    &__title {
      color: $light-black;
      @include setText(64,400,80);
      position: relative;
      &:before {
        content: '';
        width: 0;
        height: 24px;
        position: absolute;
        top: -36px;
        left: -16px;
        transition: all 1s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
        background: url("../../../assets/images/common/line.png") no-repeat 50% 50% / contain;
      }
      &--impact {
        font-weight: 700;
      }
      @include setText(28,400,36);
      &:before {
        width: 16px;
        height: 16px;
        top: -24px;
        left: -13px;
      }
    }
    &__description {
      color: $black;
      margin: 16px 0 48px;
      &-text {
        position: relative;
        &:after {
          content: '';
          height: 5px;
          position: absolute;
          background: $light-point;
          z-index: -1;
          display: inline-block;
          left: 0;
          bottom: 3px;
          width: 100%;
        }
      }
    }
  }
}
.section-bottom-img {
  background-repeat: no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  background-image: url("../../../assets/images/main/first-bottom-bg.png");
  width: 100px;
  height: 40px;
  transform-origin: left center;
  transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;
  &.left {
    left: -2.7vw;
  }
  &.right {
    right: -2.7vw;
  }
}
.first-section-img-area {
  bottom: 2vh;
  position: relative;
  right: 0;
  @include respond-to(760, 'max-height') {
    bottom: 5vh;
  }
  .first-section-img {
    width: 100%;
    @include respond-to(600) {
      padding: 0 13%;
      box-sizing: border-box;
    }
    @include respond-to(420) {
      padding: 0;
      box-sizing: border-box;
    }

  }
}
</style>
