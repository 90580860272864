import { createApp } from 'vue'
import App from './App.vue'
import VueNumber from 'vue-number-animation'
import router from './router'
import AOS from 'aos'
import 'aos/dist/aos.css'

const renderApp = () => {
  const app = createApp(App);
  app
      .use(VueNumber)
      .use(router)
      .use(AOS.init({duration: 1000, once: true}))
      .mount('#app');
};

renderApp()
