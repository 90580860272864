<template>
  <div class="main" id="fullpage">
    <div class="section first">
      <first-section />
    </div>
    <div class="section">
      <second-section :isSecondCountingStart="isSecondCountingStart"/>
    </div>
    <div class="section">
      <third-section :isThirdCountingStart="isThirdCountingStart"/>
    </div>
    <div class="section">
      <fourth-section/>
    </div>
    <div class="section">
      <fifth-section />
    </div>
    <div class="section">
      <sixth-section />
    </div>
    <div class="section">
      <seventh-section />
    </div>
    <div class="section">
      <eighth-section />
    </div>
    <div class="section">
      <ninth-section />
    </div>
    <div class="section fp-auto-height">
      <b-footer />
    </div>
  </div>
</template>

<script>
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
import FourthSection from './FourthSection'
import FifthSection from './FifthSection'
import SixthSection from './SixthSection'
import SeventhSection from './SeventhSection'
import EighthSection from './EighthSection'
import NinthSection from './NinthSection'
import BFooter from '../../layout/BFooter'
import { onMounted, onUnmounted, ref } from 'vue'
import '@/assets/style/vendor/fullpage.css'
import '@/assets/js/scrollHorizontally.js'
import '@/assets/js/scrolloverflow.js'
import fullPage from '@/assets/js/fullpage.extensions.js'

export default {
  name: 'PcIndex',
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
    SeventhSection,
    EighthSection,
    NinthSection,
    BFooter
  },
  setup() {
    const isSecondCountingStart = ref(false)
    const isThirdCountingStart = ref(false)

    onMounted(() => {
      new fullPage('#fullpage', {
        autoScrolling: true,
        scrollOverflowReset: true,
        scrollOverflow: true,
        scrollHorizontally: true,
        anchors: ['', '', '', 'department', '', '', 'video', 'comment', 'inquiry', ''],
        onLeave: function (index, nextIndex, direction) {
          document.querySelector('.section.first').classList.remove('first-active')
          if (direction === 'up') {
            document.querySelector('.ninth-section-form').classList.remove('is--show')
            if (nextIndex.index === 1) {
              // isScroll.value = false
              isSecondCountingStart.value = true
              isThirdCountingStart.value = false
            }else if(nextIndex.index === 2) {
              isThirdCountingStart.value = true
              isSecondCountingStart.value = false
            }else {
              isSecondCountingStart.value = false
              isThirdCountingStart.value = false
            }
          }else if (direction === 'down')  {
            // isScroll.value = true;
            if(index.index === 0) {
              isSecondCountingStart.value = true
              isThirdCountingStart.value = false
            }else if(index.index === 1) {
              isThirdCountingStart.value = true
              isSecondCountingStart.value = false
            }else {
              isSecondCountingStart.value = false
              isThirdCountingStart.value = false
            }
          }
          if(direction === 'down') {
            if(index.index === 8) {
              document.querySelector('.ninth-section-form').classList.add('is--show')
            }
          }
        },
        afterLoad: function(){
          document.querySelector('.section.first').classList.add('first-active')
        }
      });
    })

    onUnmounted(() => {
      window.fullpage_api.destroy('all')
    })

    return {
      isSecondCountingStart,
      isThirdCountingStart
    }
  }
}
</script>

<style lang="scss">

</style>
