<template>
  <section class="fourth-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text is--active">의료비 <b>지원항목</b></p>
    </div>
    <div class="fourth-section-list-area">
      <div class="flip-card first">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="@/assets/images/icons/ophthalmology.png" alt="" class="flip-card__icon ophthalmology">
            <strong class="flip-card__text">안과</strong>
          </div>
          <div class="flip-card-back">
            <h2 class="flip-card__title">안과</h2>
            <p class="flip-card__description">
              백내장수술<br>
              녹내장수술
            </p>
          </div>
        </div>
      </div>
      <div class="flip-card second">
        <div class="flip-card-inner">
          <div class="flip-card-front ">
            <img src="@/assets/images/icons/dental.png" alt="" class="flip-card__icon ophthalmology">
            <strong class="flip-card__text">치과</strong>
          </div>
          <div class="flip-card-back">
            <h2 class="flip-card__title">치과</h2>
            <p class="flip-card__description">
              충치치료<br>
              크라운(지르코니아)<br>
              임플란트<br>
              임플란트틀니<br>
              틀니<br>
              교정(부정교합)
            </p>
          </div>
        </div>
      </div>
      <div class="flip-card third">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="@/assets/images/icons/orthopedic.png" alt="" class="flip-card__icon ophthalmology">
            <strong class="flip-card__text">정형/신경외과</strong>
          </div>
          <div class="flip-card-back">
            <h2 class="flip-card__title">정형/신경외과</h2>
            <p class="flip-card__description">
              무릎인공관절수술<br>
              고관절인공관절수술<br>
              어깨회전근개파열수술<br>
              어깨인공관절수술<br>
            </p>
          </div>
        </div>
      </div>
      <div class="flip-card fourth">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="@/assets/images/icons/medicine.png" alt="" class="flip-card__icon ophthalmology">
            <strong class="flip-card__text">내과</strong>
          </div>
          <div class="flip-card-back">
            <h2 class="flip-card__title">내과</h2>
            <p class="flip-card__description">
              위내시경 검사<br>
              대장내시경 검사<br>
              5대암검진<br>
              대장용종절제술<br>
              위용종절제술<br>
              내시경이물제거술
            </p>
          </div>
        </div>
      </div>
      <div class="flip-card fifth">
        <div class="flip-card-inner">
          <div class="flip-card-front">
            <img src="@/assets/images/icons/otorhinolaryngology.png" alt="" class="flip-card__icon ophthalmology">
            <strong class="flip-card__text">이비인후과</strong>
          </div>
          <div class="flip-card-back">
            <h2 class="flip-card__title">이비인후과</h2>
            <div class="flip-card-description-area">
              <p class="flip-card__description">
                청각장애진단<br>
                축농증수술<br>
                만성비염수술<br>
                비중격교정술<br>
                보청기
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FourthSection'
}
</script>

<style scoped lang="scss">
.fourth-section {
  background: $point2;
  color: $white;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &.height-auto {
    height: auto;
    padding: 16vh 0 20vh;
  }
  .fourth-section-list-area {
    margin-top: 5.6vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    //transform: translateX(368px);
    @include fadeInUpPrepare(0.5);
  }
}
.flip-card {
  background-color: transparent;
  width: 280px !important;
  border-radius: 16px;
  ::v-deep .fp-tableCell {
    height: auto !important;
  }
  + .flip-card {
    margin-left: 20px;
  }
  &:hover,
  &.active {
    .flip-card-inner {
      transform: rotateY(180deg);
      &.second {
        transition-delay: .5s;
      }
      &.third {
        transition-delay: 1s;
      }
    }
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 280px;
  text-align: center;
  transition: transform 1s;
  transform-style: preserve-3d;
  &.second,
  &.third {
    //transition-delay: .2s;
    margin-top: 2vh;
  }
}

.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
  background-color: $white;
  color: $black;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;

  .flip-card {
    &__icon {
      margin-bottom: 30px;
      &.ophthalmology {
        height: 104px;
      }
    }
    &__text {
      display: block;
      @include setText(20,400,25);
    }
  }
}

.flip-card-back {
  background-color: $point;
  color: $black;
  transform: rotateY(180deg);
  border-radius: 16px;
  padding: 30px;
  box-sizing: border-box;
  text-align: left;
  display: flex;
  flex-direction: column;
  .flip-card-description-area {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .flip-card__ {
    &title {
      @include setText(28,700,35);
    }
    &description {
      margin-top: 20px;
      font-size: 14px;
      line-height: 160%;
    }
  }
}
@include pcMiddle();
.section.active {
  .fourth-section-list-area {
    @include fadeInUp();
    .flip-card {
      .flip-card-inner {
        transform: rotateY(180deg);
      }
      &.first {
        .flip-card-inner {
          transition-delay: 1s;

          &.second {
            transition-delay: 1.2s;
          }
        }
      }
      &.second {
        .flip-card-inner {
          transition-delay: 1.4s;
        }
        .second {
          transition-delay: 1.6s;
        }
      }
      &.third {
        .flip-card-inner {
          transition-delay: 1.6s;
        }
        .second {
          transition-delay: 1.8s;
        }
      }
      &.fourth {
        .flip-card-inner {
          transition-delay: 1.8s;
        }
        .second {
          transition-delay: 2s;
        }
      }
      &.fifth {
        .flip-card-inner {
          transition-delay: 2.2s;
        }
        .second {
          transition-delay: 2.4s;
        }
      }
    }

  }
}
.middle-section-top {
  padding-top: 0;
}
</style>
