<template>
  <section class="sixth-section main-section content-section">
    <div class="layout">
      <div class="sixth-section-content">
        <h2 class="sixth-section__title">
          이웃을 돕는<br>
          후원의 첫걸음,<br>
          <b>보리BOB</b>
        </h2>
        <div class="sixth-section-bottom">
          <p class="sixth-section__english">
            <b class="color--point2">B</b>lessing <b class="color--point2">O</b>f <b class="color--point2">B</b>oaz
          </p>
          <p class="sixth-section__description">
            나누는 기쁨도 보리에서 함께
          </p>
          <a href="/bob/list" class="sixth-section__button">나눔소식 바로가기</a>
        </div>
      </div>
      <div class="sixth-section-img-area">
        <div class="bg"></div>
        <img src="@/assets/images/main/sixth-img.png" alt="" class="sixth-section-img">
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SixthSection',
  setup() {

    return {
    }
  }
})
</script>

<style scoped lang="scss">
.sixth-section {
  border-top: 8px solid rgba(0, 0, 0, 0.02);
  background: $vary-light-gray2;
  .layout {
    align-items: stretch;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 630px;
  }
  &__title {
    @include setText(56,400,70);
    //margin-bottom: 7.4vh;
    @include fadeInUpPrepare(0.5);
  }
  &-img {
    &-area {
      overflow: hidden;
      position: absolute;
      right: 0;
      top: -9.6vh;
      width: 444px;
      height: 748px;

      .bg {
        width: 444px;
        height: 748px;
        position: absolute;
        top: 0px;
        left: 0px;
        display: block;
        background: $point;
        transform-origin: right;
        -ms-transform-origin: right;
        -webkit-transform-origin: right;
        -ms-transform: scaleX(0);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -ms-transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1s;
        -webkit-transition: -webkit-all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1s;
        transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1s;
      }
    }

    width: 444px;
    height: 748px;
    -ms-transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1700ms;
    -webkit-transition: -webkit-all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1700ms;
    transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1700ms;
    opacity: 0.01;
  }
  &__english {
    line-height: 140%;
    text-transform: uppercase;
    font-size: 40px;
  }
  &__description {
    margin: 16px 0 30px;
  }
  &__button {
    width: 212px;
    height: 52px;
    background: $black;
    color: $white;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .sixth-section-bottom__description {
    line-height: 18px;
  }
  .sixth-section-img-area {
  }
  &-bottom {
    @include fadeInUpPrepare(1);
  }
}
.section.active {
  .sixth-section__title {
    @include fadeInUp()
  }
  .sixth-section-bottom {
    @include fadeInUp()
  }
  .sixth-section-img-area {
    .bg {
      transform-origin: right center;
      transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 0ms;
    }

    .sixth-section-img {
      transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1) 1700ms;
      opacity: 1;
    }
  }
}
</style>
