<template>
  <section class="second-section main-section middle-section">
    <div class="middle-section-top ">
      <p class="middle-section-text second-section-title is--active text-effect-text-wrap">
        <span data-aos="text-effect">보리</span> <b data-aos="text-effect" data-aos-delay="200">누적지원건</b>
      </p>
      <p class="count__period text-effect-text-wrap">
        <span data-aos="text-effect" data-aos-delay="400">2019년 1월부터 2022년 말까지 기준</span>
      </p>
    </div>
    <div class="middle-section-count">
      <div data-aos="fade-up" data-aos-delay="600" ref="countElement" class="count-element">
        <span class="count__number target" id="numberCount"></span>
        <span class="count__unit">건</span>
      </div>
    </div>
  </section>
</template>

<script>
import { CountUp } from 'countup.js/dist/countUp.js'
import { ref, defineComponent, onMounted, nextTick, onBeforeUnmount } from 'vue'

export default defineComponent({
  name: 'SecondSection',
  components: {
  },
  setup () {
    const options = {
      startVal: 1000,
      duration: 2,
    }
    const countElement = ref(null)

    const onClassChange = (classAttrValue) => {
      const classList = classAttrValue.split(' ');
      if (classList.includes('aos-animate')) {
        const countUp = new CountUp('numberCount', 3463, options);
        countUp.start();
        observer.disconnect();
      }
    }

    const observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        nextTick(() => {
          onClassChange(newValue, m.oldValue);
        });
      }
    });

    onMounted(() => {
      observer.observe(countElement.value, {
        attributes: true,
        attributeOldValue : true,
        attributeFilter: ['class'],
      });
    })

    onBeforeUnmount(() => {
      observer.disconnect();
    })
    return {
      countElement
    }
  }
})
</script>

<style scoped lang="scss">
.second-section {
  background-color: $point;
  color: $white;
  background-image: url("../../../assets/images/main/second-bg.png");
  background-size: 257px;
  background-position: 50% 90%;
  background-position: 50% calc(100% - 9.6vh);
  background-repeat: no-repeat;
  .count__period {
    //@include fadeInUpPrepare(0.8);
  }
}
@include mobileMiddle();
//.section.active {
//  .count__period {
//    @include fadeInUp();
//  }
//}
</style>
