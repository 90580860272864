<template>
  <section class="third-section main-section middle-section">
    <div class="middle-section-top">
      <p class="middle-section-text is--active" data-aos="fade-up">
         보리를 통해<br><b>건강한 삶을 회복한 분들</b>
      </p>
    </div>
    <div class="middle-section-count">
      <div data-aos="fade-up" data-aos-delay="300"  ref="countElement2" class="count-element2">
        <span class="count__number target" id="numberCount2"></span>
        <span class="count__unit">명</span>
      </div>
      <p class="count__period" data-aos="fade-up" data-aos-delay="600">2019년 1월부터 2022년 말까지 기준</p>
    </div>
  </section>
</template>

<script>
import { CountUp } from 'countup.js/dist/countUp.js'
import { defineComponent, nextTick, onBeforeUnmount, onMounted, ref } from 'vue'

export default defineComponent({
  name: 'ThirdSection',
  components: {
  },
  setup () {
    const options = {
      startVal: 1000
    }

    const countElement2 = ref(null)

    const onClassChange = (classAttrValue) => {
      const classList = classAttrValue.split(' ');
      if (classList.includes('aos-animate')) {
        const countUp = new CountUp('numberCount2', 2753, options);
        countUp.start();
        observer.disconnect();
      }
    }

    const observer = new MutationObserver(mutations => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        nextTick(() => {
          onClassChange(newValue, m.oldValue);
        });
      }
    });

    onMounted(() => {
      observer.observe(countElement2.value, {
        attributes: true,
        attributeOldValue : true,
        attributeFilter: ['class'],
      });
    })

    onBeforeUnmount(() => {
      observer.disconnect();
    })
    return {
      countElement2
    }
  }
})
</script>

<style scoped lang="scss">
.third-section {
  color: $white;
  background-image: url("../../../assets/images/main/third-bg-img.jpeg");
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  @include respond-to() {
    background-image: url("../../../assets/images/main/third-bg-img-mobile.jpeg");
  }
  .count__period {
    @include fadeInUpPrepare(0.8);
  }
}
.section.active {
  .count__period {
    @include fadeInUp();
  }
}
@include tabletMiddle();
</style>
