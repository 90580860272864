<template>
  <section class="sixth-section main-section content-section">
    <div class="layout">
      <div class="sixth-section-content" data-aos="fade-up">
        <h2 class="sixth-section__title">
          이웃을 돕는<br>
          후원의 첫걸음,<br>
          <b>보리BOB</b>
        </h2>
        <div class="sixth-section-bottom">
          <p class="sixth-section__english">
            <b class="color--point2">B</b>lessing <b class="color--point2">O</b>f <b class="color--point2">B</b>oaz
          </p>
          <p class="sixth-section__description">
            나누는 기쁨도 보리에서 함께
          </p>
          <a href="/bob/list" class="sixth-section__button">나눔소식 바로가기</a>
        </div>
      </div>
      <div class="sixth-section-img-area" data-aos="zoom-in-up" data-aos-delay="500">
        <div class="bg"></div>
        <img src="@/assets/images/main/sixth-img.png" alt="" class="sixth-section-img">
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SixthSection',
  setup() {

    return {
    }
  }
})
</script>

<style scoped lang="scss">
.sixth-section {
  border-top: 8px solid rgba(0, 0, 0, 0.02);
  background: $vary-light-gray2;
  padding: 0 5%;
  .layout {
    align-items: stretch;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 50vw;
  }
  &__title {
    //margin-bottom: 7.4vh;
    font-weight: 400;
    @include setText(40,400,50);
  }
  &-img {
    &-area {
      overflow: hidden;
      position: absolute;
      right: 0;
      top: -6.6vh;
      width: 35vw;
      height: 60vw;
    }
    width: 100%;
  }
  &__english {
    text-transform: uppercase;
    @include setText(35,400,48);

  }
  &__description {
    margin: 16px 0 30px;
  }
  &__button {
    width: 212px;
    height: 52px;
    background: $black;
    color: $white;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .sixth-section-bottom__description {
    line-height: 18px;
  }
  .sixth-section-img-area {
  }
  &-bottom {
    //@include fadeInUpPrepare(1);
  }
}

</style>
