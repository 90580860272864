<template>
  <div class="main" >
    <div class="section first-active">
      <first-section />
    </div>
    <div class="section">
      <second-section/>
    </div>
    <div class="section">
      <third-section/>
    </div>
    <div class="section">
      <fourth-section/>
    </div>
    <div class="section">
      <fifth-section />
    </div>
    <div class="section">
      <sixth-section />
    </div>
    <div class="section">
      <seventh-section />
    </div>
    <div class="section">
      <eighth-section />
    </div>
    <div class="section">
      <ninth-section />
    </div>
    <div class="section">
      <b-footer />
    </div>
  </div>
</template>

<script>
import FirstSection from './FirstSection'
import SecondSection from './SecondSection'
import ThirdSection from './ThirdSection'
import FourthSection from './FourthSection'
import FifthSection from './FifthSection'
import SixthSection from './SixthSection'
import SeventhSection from './SeventhSection'
import EighthSection from './EighthSection'
import NinthSection from './NinthSection'
import BFooter from '../../layout/BFooter'
export default {
  name: 'tabletIndex',
  components: {
    FirstSection,
    SecondSection,
    ThirdSection,
    FourthSection,
    FifthSection,
    SixthSection,
    SeventhSection,
    EighthSection,
    NinthSection,
    BFooter
  },
  props: {
    isTablet: {
      type: Boolean
    }
  },
  setup() {
  }
}
</script>

<style lang="scss">

</style>
