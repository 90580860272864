<template>
  <div>
    <first-section-mobile />
    <second-section-mobile />
    <third-section-mobile />
    <fourth-section-mobile />
    <fifth-section-mobile />
    <sixth-section-mobile />
    <seventh-section-mobile />
    <eighth-section-mobile />
    <ninth-section-mobile />
    <b-footer :isMobileOnly="isMobileOnly"/>
  </div>
</template>

<script>
import FirstSectionMobile from './FirstSection'
import SecondSectionMobile from './SecondSection'
import ThirdSectionMobile from './ThirdSection'
import FourthSectionMobile from './FourthSection'
import FifthSectionMobile from './FifthSection'
import SixthSectionMobile from './SixthSection'
import SeventhSectionMobile from './SeventhSection'
import EighthSectionMobile from './EighthSection'
import NinthSectionMobile from './NinthSection'
import BFooter from '../../layout/BFooter'

export default {
  name: 'mobileIndex',
  components: {
    FirstSectionMobile,
    SecondSectionMobile,
    ThirdSectionMobile,
    FourthSectionMobile,
    FifthSectionMobile,
    SixthSectionMobile,
    SeventhSectionMobile,
    EighthSectionMobile,
    NinthSectionMobile,
    BFooter
  },
  props: {
    isMobileOnly: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
