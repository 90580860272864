<template>
  <section class="eighth-section main-section content-section">
    <h2 class="eighth-section__title text-effect-text-wrap">
      <span class="text-effect__text">
        국내유일
      </span>
      <span class="text-effect__text">
        의료사회공헌 플랫폼
      </span>
    </h2>
    <div class="eighth-section-list">
      <swiper
          :width="348"
          centeredSlides
          :space-between="48"
      >
        <swiper-slide>
        <div class="eighth-section-item">
          <div class="eighth-item-top">
            <p class="color--point2">서울 A병원</p>
            원장님
          </div>
          <p class="eighth-item-bottom">
            어릴적 커서 의사가 되면 남들을 돕고 살고 싶다는 꿈을 가지고 있었는데, 막상 의사가 되니 진료를 보기 바빠서 병원 밖에서 봉사나 사회공헌 활동이 쉽지가 않았습니다.<br><br>
            보아스를 통해서 많은 분들을 돕게 되어 의사로서 보람을 느끼고 기쁩니다.
          </p>
        </div>
        </swiper-slide>
        <swiper-slide>
        <div class="eighth-section-item">
          <div class="eighth-item-top">
            <p class="color--point2">경기 B병원</p>
            원장님
          </div>
          <p class="eighth-item-bottom">
            의사가 된지 20년이 되었는데 젊을적 해외 의료봉사, 무료 진료소 봉사와 같이 많은 봉사활동을 했는데 그때마다 실질적인 수술, 치료가 필요한 분들에게 도움을 드리지 못했던 것에 대해 안타까운 마음이 있었습니다.<br>
            정말 그분들에게 필요한 의료 혜택을 드리고 싶다는 꿈이 있었는데 보아스사회공헌재단을 통해 그 꿈을 이뤘습니다.<br>
            치료비가 없어 삶을 포기하는 분들을 돕고, 새 삶을 사시게 되는 것을 보면서 기쁘고 감사합니다.
          </p>
        </div>
        </swiper-slide>
        <swiper-slide>
        <div class="eighth-section-item">
          <div class="eighth-item-top">
            <p class="color--point2">서울 C병원</p>
            원장님
          </div>
          <p class="eighth-item-bottom">
            치료를 받으시면서 감사해 하시는 대상자 분들을 보면서 저뿐만아니라 직원들도 보람을 느끼고 보아스와 함께하길 잘했다고, 좋은 일에 동참해서 기쁘다고 좋아합니다.
          </p>
        </div>
        </swiper-slide>
        <swiper-slide>
        <div class="eighth-section-item">
          <div class="eighth-item-top">
            <p class="color--point2">경기 D병원</p>
            원장님
          </div>
          <p class="eighth-item-bottom">
            어려운 이웃을 돕는다는 병원으로 소문이 나서 많은 분들이 병원을 찾아주시고 성장하게 되었습니다.<br>
            사회공헌은 병원의 필수가 되었습니다. 재단을 통해 사회공헌을 손쉽게 할 수 있으니 진료에만 집중할 수 있어서 너무 편리합니다.
          </p>
        </div>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'
export default {
  name: 'EighthSection',
  components: {
    Swiper,
    SwiperSlide,
  }
}
</script>

<style scoped lang="scss">
.eighth-section {
  background: $vary-light-gray2;
  flex-direction: column;
  align-items: flex-start;
  .layout {
    flex-direction: column;
    align-items: flex-start;
  }
  &__title {
    @include setText(56,400,70);
    margin-bottom: 7.4vh;
    width: 1140px;
    margin-left: auto;
    margin-right: auto;
    .text-effect__text {
      @include fadeTextPrepare();
      & + .text-effect__text {
        @include fadeTextPrepare(0.2);
      }
    }

  }
  &-list {
    display: flex;
    width: 100%;
    ::v-deep {
      .swiper-container {
        width: 100%;
      }
      .swiper-slide {
        &:first-child {
          margin-left: 20vw !important;
          @include respond-to(1940, 'min-width') {
            margin-left: 21vw !important;
          }
          @include respond-to(2000, 'min-width') {
            margin-left: 22vw !important;
          }
          @include respond-to(1800) {
            margin-left: 15vw !important;
          }
          @include respond-to(1600) {
            margin-left: 12vw !important;
          }
          @include respond-to(1460) {
            margin-left: 10vw !important;
          }
        }
        &:last-child {
          margin-right: 0 !important;
        }
      }
      .fp-controlArrow {
        display: none;
      }
    }
  }
  &-item {
    ::v-deep {
      .fp-tableCell {
        display: block;
        height: auto !important;
      }
    }
    padding: 30px;
    width: 348px !important;
    height: 410px;
    box-sizing: border-box;
    background: $white;
    border-radius: 20px;
    -ms-transform: translate(0px,100px);
    -webkit-transform: translate(0px,100px);
    transform: translate(0px,100px);
    -ms-transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 800ms;
    -webkit-transition: -webkit-all 1500ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 800ms;
    transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 800ms;
    opacity: 0.01;
    border: 1px solid $point2;
    & + .eighth-section-item {
      -ms-transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1100ms;
      -webkit-transition: -webkit-all 1500ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1100ms;
      transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1100ms;
      & + .eighth-section-item {
        -ms-transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1400ms;
        -webkit-transition: -webkit-all 1500ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1400ms;
        transition: all 1000ms cubic-bezier(0.645, 0.045, 0.355, 1.000) 1400ms;
      }
    }
    .eighth-item- {
      &top {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
      }
      &bottom {
        margin-top: 20px;
        font-size: 14px;
        word-break: keep-all;
        line-height: 180%;
        color: $black;
      }
    }
    &:first-child {
      //margin-left: 20.3125vw;
    }
  }
}
.section.active {
  .eighth-section__title {
    .text-effect__text {
      @include fadeText();
    }
  }
  .eighth-section-list {
    .eighth-section-item {
      -ms-transform: translate(0px,0px);
      -webkit-transform: translate(0px,0px);
      transform: translate(0px,0px);
      opacity: 1;
    }
  }

}
</style>
