<template>
  <header :class="['header js-header', { 'scroll-fixed' : isScroll}, {'is--open': isMenuOpen}, {'is--mobile': isMobileOnly}]">
    <h1 class="header-logo">
      <a href="/" class="header-logo__link">보리</a>
    </h1>
    <button class="header-menu-button js-header-menu-button" @click="toggleMenu">
      <img src="@/assets/images/common/menu.png" alt="" class="menu-img">
    </button>
    <nav class="header-menu">
      <button class="header-menu-close-button js-header-menu-close-button" @click="toggleMenu">
        <img src="@/assets/images/common/menu-close.png" alt="닫기" class="menu-img">
      </button>
      <ul class="header-menu-list">
        <li class="header-menu__item">
          <a href="https://www.boaz.or.kr/company/" target="_blank" class="header-menu__link">
            재단소개
          </a>
        </li>
        <li class="header-menu__item"  @click="clickMenu">
          <router-link to="/notice/list" class="header-menu__link">
            새소식
          </router-link>
        </li>
        <li class="header-menu__item">
          <router-link to="/bob/list" class="header-menu__link">
            보리BOB
          </router-link>
        </li>
        <li class="header-menu__item" @click="clickMenu('inquiry')">
          <a v-if="!isIOS" href="/#inquiry" class="header-menu__link">
            병원협력문의
          </a>
          <router-link to="/#inquiry" v-else class="header-menu__link">
            병원협력문의
          </router-link>
        </li>
      </ul>
      <a href="https://tosto.re/boriapp" target="_blank" class="header__download-link">앱다운로드</a>
    </nav>
  </header>
</template>

<script>
import { ref } from 'vue'
import { isIOS } from 'mobile-device-detect'

export default {
  name: 'BHeader',
  props: {
    isScroll: {
      type: Boolean,
      default: false
    },
    isMobileOnly: {
      type: Boolean
    }
  },
  setup() {
    const isMenuOpen = ref(false)
    const toggleMenu = () => {
      isMenuOpen.value =! isMenuOpen.value
    }
    const clickMenu = (move) => {
      isMenuOpen.value = false
      if(isIOS) {
        const inquiryTop = document.querySelector('#inquiry').getBoundingClientRect().top
        if (move === 'inquiry') {
          window.scroll({
            top: inquiryTop,
            behavior: 'smooth'
          });
        }
      }
    }

    return {
      isMenuOpen,
      clickMenu,
      isIOS,
      toggleMenu
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  position: fixed;
  width: 100%;
  z-index: 15;
  height: 80px;
  padding: 0 1.56vw;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;
  transition: background-color .5s;
  background-color: $white;

  &.is--mobile {
    background: $white;
    padding: 0 16px;
    height: 48px;
    .header {
      &-logo {
        width: 50px;
        height: 24px;
        &__link {
          width: 50px;
          height: 24px;
        }
      }
      &-menu {
        position: fixed;
        height: 100%;
        right: -100%;
        width: 100%;
        top: 0;
        &-button {
          display: block;
        }
        &-list {
          justify-content: flex-start;
          width: 91%;
          height: 100%;
          display: flex;
          flex-direction: column;
          background: $white;
          margin-left: auto;
          padding-top: 10vh;
          box-sizing: border-box;
          .header-menu__link {
            padding: 1rem 6.6vw;
            font-size: 16px;
          }
        }
      }
      &__download-link {
        display: none;
      }
    }
  }

  &.scroll-fixed {
    background-color: $white;
  }
  &-logo {
    width: 79px;
    height: 38px;
    text-indent: -9999px;
    box-sizing: border-box;
    &__link {
      background-repeat: no-repeat;
      background-image: url("../../assets/images/common/logo.png");
      background-size: contain;
      display: block;
      width: 79px;
      height: 38px;
    }
  }
  &-menu {
    display: flex;
    z-index: 33;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all .5s ease;
    &-button {
      position: absolute;
      right: 16px;
      top: 12px;
      display: none;
      .menu-img {
        width: 24px;
      }
    }
    &:before {
      content: '';
      position: fixed;
      width: 100%;
      height: 100%;
      background: #000;
      top: 0;
      left: 0;
      opacity: 0.8;
      display: none;
      z-index: 1;
    }
    &-list {
      display: flex;
      z-index: 11;
    }
    .header-menu__link {
      padding: 1rem 1.25vw;
      font-size: 17px;
      letter-spacing: -0.5px;
      color: $black;
      box-sizing: border-box;
      position: relative;
      display: block;
      width: 100%;
      font-weight: normal;
      background-repeat: no-repeat;
      background-position: 95% 50%;
    }
    .header-menu-close-button {
      display: none;
      position: fixed;
      right: 4.44vw;
      top: 6.66vw;
      z-index: 20;
      .menu-img {
        width: 28px;
      }
    }
    .header__download-link {
      width: 136px;
      height: 44px;
      display: block;
      background-color: $point2;
      background-image: url("../../assets/images/icons/download.png");
      background-size: 20px;
      background-position: 20px 50%;
      background-repeat: no-repeat;
      color: $white;
      line-height: 47px;
      padding-left: 48px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-left: 0.83vw;
    }
  }
  &.is--open {
    .header-menu {
      right: 0;

      &:before {
        display: block;
      }

      .header-menu-close-button {
        display: block;
      }
    }
  }
}
</style>
