<template>
  <b-header v-if="!isMobileOnly"/>
  <mobile-top v-else/>
  <div class="content">
    <router-view />
    <template v-if="!isMobileOnly">
      <div class="content-bg content-bg-left">
        <img src="@/assets/images/main/first-img-left.png" alt="" >
      </div>
      <div class="content-bg content-bg-right">
        <img src="@/assets/images/main/first-img-right.png" alt="" >
      </div>
      <div class="section-bottom-img left"></div>
      <div class="section-bottom-img right"></div>
    </template>
  </div>
  <b-footer :isMobileOnly="isMobileOnly"/>
</template>

<script>
import BHeader from '../../components/layout/BHeader'
import MobileTop from '../../components/layout/MobileTop'
import BFooter from '../../components/layout/BFooter'
import { isMobileOnly } from 'mobile-device-detect'

export default {
  name: 'contentLayout',
  components: {
    BHeader,
    BFooter,
    MobileTop
  },
  setup () {

    return {
      isMobileOnly
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  min-height: 100vh;
  padding: 0 6.66vw;
  box-sizing: border-box;
  @include respond-to(960, 'min-width') {
    padding-top: 169px;
    padding-bottom: 60px;
    position: relative;
  }

  &__title {
    @include setText(40, 700, 50);
    position: relative;
    margin-bottom: 50px;

    &:before {
      content: '';
      @include pointTick();
      position: absolute;
      top: -36px;
      left: -16px;
    }
  }

  @include respond-to(960, 'min-width') {

    .content-bg {
      position: absolute;
      bottom: 0;
      z-index: -1;

      &.content-bg-left {
        right: 48.11vw;
        width: 140px;
        @include respond-to(1440) {
          right: 47.56vw;
        }
      }

      &.content-bg-right {
        right: 19.18vw;
        width: 141px;
        @include respond-to(1440) {
          right: 9.65vw;
        }
      }
    }
    .section-bottom-img {
      height: 44px;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: 0;
      background-image: url("../../assets/images/main/first-bottom-bg.png");
      width: 104px;
      transform-origin: left center;
      transition: all .5s cubic-bezier(0.645, 0.045, 0.355, 1) 1s;

      &.left {
        left: 7.7vw;
        @include respond-to(1440) {
          left: 2.7vw;
        }
      }

      &.right {
        right: 7.7vw;
        @include respond-to(1440) {
          right: 2.7vw;
        }
      }
    }
  }
}
</style>
