<template>
  <b-header :is-scroll="isScroll" :isMobileOnly="isMobileOnly" />
  <pc-main v-if="!isMobileOnly && !isTablet" />
  <tablet-main v-else-if="isTablet" />
  <mobile-main v-else :isMobileOnly="isMobileOnly"/>
  <a href="https://tosto.re/boriapp" target="_blank" v-if="isMobileOnly" :class="['app-quick-link', {'scroll-down': isScrollDirection}]">앱 다운로드 바로가기</a>
</template>

<script>
import BHeader from '../components/layout/BHeader'
import PcMain from '../components/pc/main/Index'
import TabletMain from '../components/tablet/main/Index'
import MobileMain from '../components/mobile/main/Index'
import { onMounted, ref, defineComponent } from 'vue'
import { isMobileOnly, isTablet } from 'mobile-device-detect'
export default defineComponent({
  name: 'Index',
  components: {
    BHeader,
    PcMain,
    MobileMain,
    TabletMain
  },
  setup() {
    const isScroll = ref(false)

    const checkScroll = () => {
      const isScrollDirection = ref(false)
      const scrollDirection = () => {
        let scrollPos = 0;
        window.addEventListener('scroll', (event) => {
          isScrollDirection.value = (document.body.getBoundingClientRect()).top < scrollPos
          scrollPos = (document.body.getBoundingClientRect()).top
          const element = event.target.scrollingElement;
          if (element.scrollHeight - element.scrollTop === element.clientHeight) {
            isScrollDirection.value = false
          }
        })
      }
      onMounted(() => {
        scrollDirection()
      })
      return { isScrollDirection }
    }

    return {
      isMobileOnly,
      isTablet,
      isScroll,
      ...checkScroll()
    }
  }
})
</script>

<style lang="scss">
.main {
  transition: all 1000ms ease 0s;
  transform: translate3d(0px, 0px, 0px);
}
.app-quick-link {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 56px;
  background: $black;
  color: $white !important;
  font-size: 16px;
  transform: translateY(0);
  transition: transform 1s;
  z-index: 11;
  &.scroll-down {
    transform: translateY(56px);
  }
}

.content-section {
  display: flex;
  align-items: center;
  justify-content: center;
  .layout {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
}

</style>
