<template>
  <section class="seventh-section main-section content-section">
    <div class="layout">
      <h2 class="seventh-section__title text-effect-text-wrap">
        <span class="text-effect__text">
        보이는 희망
        </span>
        <span class="text-effect__text">
        아름다운 미래
        </span>
      </h2>
    </div>
    <div class="seventh-section-slide-area">
      <div class="slide-list">
        <div class="slide-item slide">
          <iframe width="676" height="426" src="https://www.youtube.com/embed/IItt4h7sdHI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="slide-item slide">
          <a href="https://bori-app.notion.site/1587b7eec68a40fdbffa006a79688b31" target="_blank">
            <img src="@/assets/images/main/video-img2.png" alt="" class="">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeventhSection',
  setup() {

    return {
    }
  }
})
</script>

<style scoped lang="scss">
.seventh-section {
  flex-direction: column;
  overflow: hidden;
  ::v-deep {
    .fp-controlArrow {
      display: none;
    }
  }
  .layout {
    display: block;
  }
  &__title {
    @include setText(56,400,70);
    margin-bottom: 7.4vh;
    .text-effect__text {
      @include fadeTextPrepare(0.2);
      & + .text-effect__text {
        @include fadeTextPrepare(0.5);
      }
    }
  }
  &__description {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 11.11vw;
    padding-left: 8.88vw;
  }
  &-slide-area {
    @include fadeInUpPrepare(1);
    width: 100%;
    height: 426px;
    position: relative;
    .slide-list {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      width: 100%;
      ::v-deep {
        .fp-slidesContainer {
          height: auto !important;

          .fp-tableCell {
            height: auto !important;
          }
        }
      }

      .slide-item {
        width: 676px !important;
        margin-left: 242px;
        & + .slide-item {
          margin-right: 40vw;
        }
        &:first-child {
          margin-left: 32vw;
        }
      }
    }
  }
}
.section.active {
  .seventh-section__title {
    .text-effect__text {
      @include fadeText();
    }
  }
  .seventh-section-slide-area {
    @include fadeInUp();
  }
}
</style>
