import { ref } from 'vue'
import axios from 'axios'
import _ from 'lodash'


export default () => {
  const inputs = ref({
    hospitalName: '',
    tel1: '',
    tel2: '',
    area: '',
    address: '',
    activityHistory: '',
    managerName: '',
    managerTel: '',
    managerEmail: '',
    path: '',
    activityPlan: '',
    agree: false
  })


  const send = _.throttle(async () => {
    if (!inputs.value.agree) {
      alert('개인정보 수집 및 이용에 대해 동의해주세요.')
      return
    }
    if (!inputs.value.hospitalName) {
      alert('병원이름을 입력해 주세요.')
      return
    }
    if (!inputs.value.tel1 || !inputs.value.tel2) {
      alert('병원전화번호를 입력해 주세요.')
      return
    }
    if (!inputs.value.area || !inputs.value.address) {
      alert('지역과 주소를 입력해 주세요.')
      return
    }
    if (!inputs.value.managerName || !inputs.value.managerTel || !inputs.value.managerEmail) {
      alert('담당자 정보를 입력해 주세요.')
      return
    }

    try {
      const { data: { message } } = await axios.post(`${process.env.VUE_APP_API_HOST}/api/common/mail-contact`, {
        body: getTemplate(inputs.value)
      })
      alert(message)
      inputs.value = {
        hospitalName: '',
        tel1: '',
        tel2: '',
        area: '',
        address: '',
        activityHistory: '',
        managerName: '',
        managerTel: '',
        managerEmail: '',
        path: '',
        activityPlan: '',
        agree: false
      }
      sendNaverLog()
    } catch (e) {
      alert('메일전송을 실패했습니다.')
    }

  }, 5000)

  const sendNaverLog = () => {
    try {
      const _nasa = {}
      if(window.wcs) {
        _nasa["cnv"] = window.wcs.cnv("4","1")
        window.wcs_do(_nasa)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return {
    inputs,
    send
  }
}

const replace = (text) => text?.replace(/\n/g, '<br/>')



const getTemplate = (inputs) => {
  const {
    hospitalName,
    tel1,
    tel2,
    area,
    address,
    activityHistory,
    managerName,
    managerTel,
    managerEmail,
    path,
    activityPlan,
  } = inputs

  return `
    <!DOCTYPE html>
    <html lang="ko">
    <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>협력문의</title>
    </head>
    <body>
      <div
      style="max-width: 700px; font-family: '나눔고딕',NanumGothic,'맑은고딕',Malgun Gothic,dotum,'돋움',Dotum,Helvetica; width: 100%; background: #fff; letter-spacing: -1px; padding: 0px; margin: 0px auto;">
      <!--#메일헤더-->
      <div style="padding: 24px 0px 10px 0px; text-align: left">
        <!--#헤더타입 : 텍스트-->
        <h1 style="font-size: 18px; font-weight: bold; color: #333; margin: 0 auto; padding: 10px 0px 10px 0px">
          보리: 협력 문의</h1>
        <!--#메일제목-->
      </div>
      <!--#메일내용 시작-->
      <div style="width: 100%; padding: 0px; margin: 0px;" cellpadding="0" cellpadding="0" border="0">
        <!--#메일본문 : 진짜 내용-->
        <table width="700" cellpadding="0" cellspacing="0">
          <tbody>
          <tr>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7" style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              병원이름
            </th>
            <td align="left" valign="middle" width="145" height="56" style="padding-left:30px;color:#666;font-size:12px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              ${hospitalName}
            </td>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7"
                style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              병원전화번호
            </th>
            <td align="left" valign="middle" width="145" style="padding-left:30px;color:#666;font-size:12px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              ${tel1}-${tel2}
            </td>
          </tr>
          <tr>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7" style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-bottom:1px solid #f0f0f0;">
              지역
            </th>
            <td align="left" colspan="3" valign="middle" style="padding-left:30px;color:#666;font-size:12px;border-bottom:1px solid #f0f0f0;">
              ${area}
            </td>
          </tr>
          <tr>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7" style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-bottom:1px solid #f0f0f0;">
              상세주소
            </th>
            <td align="left" colspan="3" valign="middle" style="padding-left:30px;color:#666;font-size:12px;border-bottom:1px solid #f0f0f0;">
              ${address}
            </td>
          </tr>
          <tr>
            <th align="left" colspan="4" rowspan="1" width="145" height="56" bgcolor="#f7f7f7" style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-bottom:1px solid #f0f0f0;">
              사회공헌 활동이력
            </th>
          </tr>
          <tr>
            <td align="left" colspan="4" valign="middle" height="200" style=" padding: 15px;color:#666;font-size:12px;">
              ${replace(activityHistory)}
            </td>
          </tr>
          <tr>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7" style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              담당자 이름/직함
            </th>
            <td align="left" valign="middle" width="145" height="56" style="padding-left:30px;color:#666;font-size:12px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              ${managerName}
            </td>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7"
                style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              담당자 연락처
            </th>
            <td align="left" valign="middle" width="145"
                style="padding-left:30px;color:#666;font-size:12px;border-top:1px solid #f0f0f0;border-bottom:1px solid #f0f0f0;">
              ${managerTel}
            </td>
          </tr>
          <tr>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7"
                style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-bottom:1px solid #f0f0f0;">
              담당자 이메일
            </th>
            <td align="left" valign="middle" width="145" height="56"
                style="padding-left:30px;color:#666;font-size:12px;border-bottom:1px solid #f0f0f0;">
              ${managerEmail}
            </td>
            <th align="left" colspan="1" rowspan="1" width="145" height="56" bgcolor="#f7f7f7"
                style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-bottom:1px solid #f0f0f0;">
              인입경로
            </th>
            <td align="left" valign="middle" width="145"
                style="padding-left:30px;color:#666;font-size:12px;border-bottom:1px solid #f0f0f0;">
              ${path}
            </td>
          </tr>
          <tr>
            <th align="left" colspan="4" rowspan="1" width="145" height="56" bgcolor="#f7f7f7"
                style="padding-left:30px;font-size:12px;font-weight:normal;color:#222;line-height:20px;border-bottom:1px solid #f0f0f0;">
              원하는 사회공헌 내용 및 협력 후 활동계획
            </th>
          </tr>
          <tr>
            <td align="left" colspan="4" valign="middle" height="200"
                style=" padding: 15px;color:#666;font-size:12px;border-bottom:1px solid #f0f0f0;">
              ${replace(activityPlan)}
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <!--#메일푸터-->
      <div
        style="font-size: 11px; color: #222; text-align: left; padding: 25px 20px 25px 20px; line-height: 16px; background-color: #f7f7f7">
        본 메일은 발신전용으로 회신되지 않습니다.<br/>궁금하신 사항은 <span style="text-decoration: none; font-weight: bold">고객센터</span>를 이용해 주시기
        바랍니다.
        <span style="display: block; color: #222; padding: 10px 0px 10px 0px"><br/>사회적협동조합 보아스사회공헌재단</span>
        (우)16943 경기도 용인시 수지구 광교중앙로296번길 14, 207호<br>
        대표전화 : 1661-1402 (내선1번 : 의료비지원 상담 / 내선2번 : 재단사무실)<br>
        FAX : 031-548-4552
      </div>
    </div>
    </body>
    </html> 
  `
}
