<template>
  <section class="sixth-section main-section content-section">
    <div class="layout">
      <div class="sixth-section-content" data-aos="fade-up">
        <h2 class="sixth-section__title">
          이웃을 돕는<br>
          후원의 첫걸음,<br>
          <b>보리BOB</b>
        </h2>
        <div class="sixth-section-bottom">
          <p class="sixth-section__english">
            <b class="color--point2">B</b>lessing <b class="color--point2">O</b>f <b class="color--point2">B</b>oaz
          </p>
          <p class="sixth-section__description">
            나누는 기쁨도 보리에서 함께
          </p>
        </div>
      </div>
      <div class="sixth-section-img-area" data-aos="zoom-in-up" data-aos-delay="300">
        <div class="bg"></div>
        <img src="@/assets/images/main/sixth-img.png" alt="" class="sixth-section-img">
        <a  href="/bob/list" class="sixth-section__button">나눔소식 바로가기</a>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SixthSection',
  setup() {
  }
})
</script>

<style scoped lang="scss">
.sixth-section {
  border-top: 8px solid rgba(0, 0, 0, 0.02);
  background: $vary-light-gray2;
  padding-bottom: 12vh;
  .layout {
    align-items: stretch;
    display: block;
  }
  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 12vh;
    padding-left: 8.88vw;
  }
  &__title {
    margin-bottom: 10px;
    @include setText(28,400,36);
  }
  &__english {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.2;
  }
  &__button {
    position: absolute;
    width: calc(100% - 32px);
    left: 16px;
    height: 52px;
    bottom: 16px;
    background: $black;
    color: $white;
    border-radius: 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .sixth-section-bottom__description {
    line-height: 18px;
  }
  .sixth-section-img-area {
    width: 86.66%;
    margin: 0 auto;
    position: relative;
  }
  &-bottom {
    //@include fadeInUpPrepare(1);
  }
}
</style>
