<template>
  <section class="seventh-section main-section content-section">
    <div class="layout">
      <h2 class="seventh-section__title text-effect-text-wrap">
        <span class="text-effect__text"  data-aos="text-effect">
        보이는 희망
        </span>
        <span class="text-effect__text" data-aos="text-effect"  data-aos-delay="200">
        아름다운 미래
        </span>
      </h2>
      <p class="seventh-section__description text-effect-text-wrap">
        <span data-aos="text-effect"  data-aos-delay="500">10개 과목, 최대 50항목</span>
      </p>
    </div>
    <div class="seventh-section-slide-area">
      <div class="slide-list">
        <div class="slide-item" data-aos="fade-up" data-aos-delay="400">
          <iframe class="slide-item__video" width="676" height="426" src="https://www.youtube.com/embed/IItt4h7sdHI?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
        <div class="slide-item" data-aos="fade-up" data-aos-delay="600">
          <a href="https://bori-app.notion.site/1587b7eec68a40fdbffa006a79688b31" target="_blank">
            <img src="@/assets/images/main/video-img2.png" alt="" class="">
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SeventhSection',
  setup() {
    return {
    }
  }
})
</script>

<style scoped lang="scss">
.seventh-section {
  flex-direction: column;
  overflow: hidden;
  padding-bottom: 12vh;

  ::v-deep {
    .fp-controlArrow {
      display: none;
    }
  }
  .layout {
    display: block;
  }
  &__title {
    padding-top: 12vh;
    padding-left: 8.88vw;
    margin-bottom: 8px;
    @include setText(24,700,31);
  }
  &__description {
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 11.11vw;
    padding-left: 8.88vw;
  }
  &-slide-area {
    padding: 0 6.66vw;
    .slide-item {
      margin-top: 8.3vw;
      &__video {
        width: 100%;
        height: 500px;
        @include respond-to(660) {
          height: 400px;
        }
        @include respond-to(450) {
          height: 200px;
        }
        @include respond-to(300) {
          height: 150px;
        }
      }
    }
  }
}

</style>
