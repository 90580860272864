<template>
  <section class="ninth-section main-section content-section height-auto">
    <div class="layout">
      <div class="ninth-section-title-area">
        <h2 class="ninth-section__title text-effect-text-wrap">
          <span class="text-effect__text">
          협력
          </span>
          <span class="text-effect__text">
          문의
          </span>
        </h2>
        <p class="ninth-section__description text-effect-text-wrap">
          <span class="text-effect__text">
          보리를 통해 의료 사회공헌을 쉽게!
          </span>
          <span class="text-effect__text">
          지역사회 업무협약,의료봉사,재능기부 등의
          </span>
          <span class="text-effect__text">
          사회공헌 활동이 어려우셨죠?
          </span>
          <span class="text-effect__text">
          보리가 도와드립니다.
          </span>
        </p>
      </div>
      <div class="ninth-section-form">
        <div class="form-part">
          <label for="hospitalName" class="input__label">병원이름</label>
          <input v-model="inputs.hospitalName" type="text" id="hospitalName" class="input__input full" placeholder="병원 이름을 입력해 주세요.">
        </div>
        <div class="form-part">
          <label for="hospitalTel" class="input__label">병원전화번호</label>
          <div class="form-part-select">
            <select v-model="inputs.tel1" class="input__select">
              <option value="">지역번호</option>
              <option value="02">02</option>
              <option value="031">031</option>
              <option value="032">032</option>
              <option value="033">033</option>
              <option value="041">041</option>
              <option value="042">042</option>
              <option value="043">043</option>
              <option value="044">044</option>
              <option value="051">051</option>
              <option value="052">052</option>
              <option value="053">053</option>
              <option value="054">054</option>
              <option value="055">055</option>
              <option value="061">061</option>
              <option value="062">062</option>
              <option value="063">063</option>
              <option value="064">064</option>
            </select>
            <input v-model="inputs.tel2" type="text" maxlength="8" id="hospitalTel" class="input__input" placeholder="‘-”없이 번호만 입력해주세요.">
          </div>
        </div>
        <div class="form-part">
          <label for="tel" class="input__label">지역</label>
          <div class="form-part-select">
            <select v-model="inputs.area" class="input__select">
              <option value="">지역을 선택해 주세요.</option>
              <option value="서울특별시">서울특별시</option>
              <option value="부산광역시">부산광역시</option>
              <option value="대구광역시">대구광역시</option>
              <option value="인천광역시">인천광역시</option>
              <option value="광주광역시">광주광역시</option>
              <option value="대전광역시">대전광역시</option>
              <option value="울산광역시">울산광역시</option>
              <option value="세종특별자치시">세종특별자치시</option>
              <option value="경기도">경기도</option>
              <option value="강원도">강원도</option>
              <option value="충청북도">충청북도</option>
              <option value="충청남도">충청남도</option>
              <option value="전라북도">전라북도</option>
              <option value="전라남도">전라남도</option>
              <option value="경상북도">경상북도</option>
              <option value="경상남도">경상남도</option>
              <option value="제주도">제주도</option>
            </select>
          </div>
        </div>
        <div class="form-part">
          <label for="address" class="input__label">상세주소</label>
          <input v-model="inputs.address" type="text" id="address" class="input__input full" placeholder="상세 주소를 입력해 주세요.">
        </div>
        <div class="form-part">
          <label class="input__label">사회공헌 활동이력</label>
          <textarea v-model="inputs.activityHistory" class="input__input full textarea">1,000자 이내로 입력해 주세요.</textarea>
        </div>
        <div class="form-part">
          <label for="name" class="input__label">담당자 이름/직함</label>
          <input v-model="inputs.managerName" type="text" id="name" class="input__input full" placeholder="담당자 이름/직함을 입력해 주세요.">
        </div>
        <div class="form-part">
          <label for="mtel" class="input__label">담당자 연락처</label>
          <input v-model="inputs.managerTel" type="text" id="mtel" class="input__input full" placeholder="담당자 연락처를 입력해 주세요.">
        </div>
        <div class="form-part">
          <label for="email" class="input__label">담당자 이메일</label>
          <input v-model="inputs.managerEmail" type="email" id="email" class="input__input full" placeholder="담당자 이메일을 입력해 주세요.">
        </div>
        <div class="form-part">
          <label for="path" class="input__label">인입경로</label>
          <input v-model="inputs.path" type="text" id="path" class="input__input full" placeholder="인입경로를 입력해 주세요.">
        </div>
        <div class="form-part">
          <label class="input__label">원하는 사회공헌 내용 및 협력 후 활동계획</label>
          <textarea v-model="inputs.activityPlan" class="input__input full textarea">1,000자 이내로 입력해 주세요.</textarea>
        </div>
        <div class="form-line"></div>
        <p class="form-agreement__title">개인정보동의</p>
        <div class="form-agreement-box">
          <p class="form-agreement-text">
            사회적협동조합 보아스사회공헌재단은 서비스 제공을 위해서 아래와 같이 개인정보를 수집합니다.<br>
            서비스 제공에 필요한 최소한의 개인 정보 이므로 동의를 해주셔야 서비스를 이용하실 수 있습니다.<br>
            &#183; 개인정보 항목: 이름, 전화번호, e-mail, 병원정보(병원명, 전화번호, 주소, 담당자 인적사항 등)<br>
            &#183; 수집 및 이용 목적: 협력 문의 상담<br>
            &#183; 보유 및 이용기간: 이용계약 종료 후 즉시 파기
          </p>
          <div class="agreement-check-part">
            <input v-model="inputs.agree" id="agreeCheck" type="checkbox" name="agreeCheck" value="Y" class="input__input type--radio js-agree-check">
            <label for="agreeCheck" class="input__label type--radio">개인 정보의 수집 및 이용에 대해 동의합니다. (필수)</label>
          </div>
        </div>
        <button @click="send" class="form-button">확인</button>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue'
import useSendEmail from '@/mixins/useSendEmail'

export default defineComponent({
  name: 'NinthSection',
  setup() {
    const { inputs, send } = useSendEmail()

    return {
      inputs,
      send
    }
  }
})
</script>

<style scoped lang="scss">
.ninth-section {
  &.height-auto {
    height: auto;
    padding: 18.5vh 0;
  }
  .layout {
    align-items: flex-start;
  }
  .ninth-section-title-area {
    width: 49%;
    flex: none;
  }
  .ninth-section-form {
    width: 49%;
    @include fadeInUpPrepare(1.5);
  }
  &__title {
    @include setText(56,400,70);
    margin-bottom: 16px;
    .text-effect__text {
      @include fadeTextPrepare(0.2);
      & + .text-effect__text {
        @include fadeTextPrepare(0.5);
        & + .text-effect__text {
          @include fadeTextPrepare(0.7);
        }
      }
    }
  }
  &__description {
    line-height: 1.8;
    .text-effect__text {
      @include fadeTextPrepare(0.5);
      & + .text-effect__text {
        @include fadeTextPrepare(0.8);
        & + .text-effect__text {
          @include fadeTextPrepare(1.1);
        }
      }
    }
  }
  &-list {
    display: flex;
  }
  &-item {
    padding: 30px;
    width: 348px;
    height: 420px;
    box-sizing: border-box;
    background: $white;
    border-radius: 20px;
    & + .ninth-section-item {
      margin-left: 48px;
    }
    .ninth-item- {
      &top {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
      }
      &bottom {
        margin-top: 20px;
        font-size: 14px;
        word-break: keep-all;
        line-height: 180%;
        color: $black;
      }
    }
  }
}
.form-part {
  &-select {
    display: flex;
    justify-content: space-between;
  }
  & + .form-part {
    margin-top: 25px;
  }
}
.input {
  &__input {
    padding: 9px 12px;
    height: 38px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
    width: 58.57%;
    color: $grey;
    &.textarea {
      height: 160px;
      resize: none;
    }
    &.full {
      width: 100%;
    }
    &.type--radio {
      width: 20px;
      height: 20px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0;
      -webkit-print-color-adjust: exact;
      color-adjust: exact;
      display: inline-block;
      vertical-align: middle;
      background-origin: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      flex-shrink: 0;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #D4D7DB;
      &:checked {
        background-position: center;
        background-repeat: no-repeat;
        background-image: url("../../../assets/images/icons/check.png");
        background-size: 11px;
      }
    }
  }
  &__select {
    padding: 9px 12px;
    height: 38px;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
    width: 37.85%;
    -webkit-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    background-position: 95% 50%;
    background-image: url("../../../assets/images/icons/select.png");
    background-size: 18px;
    color: $grey;
    background-color: $white;
  }
  &__label {
    color: $black;
    font-size: 12px;
    line-height: 140%;
    display: block;
    margin-bottom: 8px;
  }
}
.form-line {
  height: 2px;
  background: $black;
  margin: 40px 0 20px;
}
.form-agreement-box {
  @include respond-to(960, 'min-width') {
    padding: 20px;
    background: #F6FFFE;
    border-radius: 10px;
    box-sizing: border-box;
  }
  .form-agreement-text {
    font-size: 13px;
    line-height: 180%;
    color: $black;
    margin-bottom: 30px;

  }
  .agreement-check-part {
    padding: 12px 14px 12px 12px;
    border-radius: 4px;
    background: $point;
    display: flex;
    align-items: center;
    .input__input {
      margin-right: 10px;
    }
    .input__label {
      margin-bottom: 0;
      @include setText(14,400,21);
      letter-spacing: -0.01em;
    }
  }
}
.form-button {
  border-radius: 4px;
  background: $black;
  color: $white;
  width: 212px;
  height: 42px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.01em;
  margin-top: 20px;
  margin-left: auto;
  display: block;
  margin-right: auto;
}
.form-agreement__title {
}
.section {
  &.active {
    .text-effect-text-wrap {
      .text-effect__text {
        @include fadeText();
      }
    }

    .ninth-section-form {
      @include fadeInUp()
    }
  }
  .ninth-section-form {
    &.is--show {
      @include fadeInUp()
    }
  }
}
</style>
