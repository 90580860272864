<template>
  <header class="mobile-top">
    <button class="mobile-top__back" @click="goBack"><span class="blind">뒤로</span></button>
    <h1 class="mobile-top__title">{{ title }}</h1>
  </header>
</template>

<script>
import { useRouter, useRoute } from 'vue-router'
import { computed } from 'vue'
export default {
  name: 'MobileTop',
  setup () {
    const router = useRouter()
    const route = useRoute()

    const title = computed(() => {
      return route.name
    })

    const goBack = () => {
      router.push('/')
    }

    return {
      goBack,
      title
    }
  }
}
</script>

<style scoped lang="scss">
.mobile-top {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  position: relative;
  &__back {
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-image: url("../../assets/images/icons/back.png");
    background-size: 24px;
    position: absolute;
    left: 12px;
    top: 12px;
  }
  &__title {
    color: $black2;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
