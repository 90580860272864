<template>
  <footer :class="['footer', {'is--mobile' : isMobileOnly}]">
    <div class="footer-logo"></div>
    <div class="footer-bottom">
      <div class="footer-left footer-content">
        <p class="footer__item">
          <strong class="footer__item-bold">사회적협동조합 보아스사회공헌재단</strong>
          (우)16953 경기도 용인시 기흥구 흥덕중앙로 55, 427호<br>
          이사장 : 이상태<br>
          고유번호 : 116-82-15051<br>
          대표전화 : 1661-1402<br>
          FAX : 031-548-4552
        </p>
      </div>
      <div class="footer-right footer-content">
        <p class="footer__item">
          <strong class="footer__item-bold">후원전용계좌</strong>
          신한 100-035-140898<br>
          (예금주: 사회적협동조합 보아스사회공헌재단)<br>
          기부금영수증 등 후원내역 관리를 위해서 계좌입금 후 연락부탁드립니다<br>
        </p>
        <p class="footer__item">Copyright (C) BOAZ FOUNDATION. All Rights Reserved.</p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'BFooter',
  props: {
    isMobileOnly: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  padding: 40px;
  background: #666666;
  color: $white;
  &.is--mobile {
    padding: 40px 6.66vw 22.22vw;
    .footer {
      &-content {
        width: 100%;
      }
      &-bottom {
        display: block;
      }
    }
  }
  &-bottom {
    display: flex;
    justify-content: space-between;
  }
  &-content {
    width: 50%;
  }
  &-logo {
    width: 79px;
    height: 38px;
    background-image: url("../../assets/images/common/footer-logo.png");
    background-size: contain;
  }
  &__item {
    font-size: 14px;
    line-height: 140%;
    font-weight: 300;
    @include respond-to() {
      font-size: 12px;
    }
    & + .footer__item {
      margin-top: 20px;
    }
    &-bold {
      display: block;
      margin: 20px 0 10px;
    }
  }
}
</style>
